import React, {useState} from "react";
import {useTheme} from "./useTheme";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Button, NavbarBrand} from "react-bootstrap";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import {useLocation} from "react-router-dom";
import {Location} from "@remix-run/router/history";
import {useTranslation} from "react-i18next";
import {changeLanguage} from "../../util/changeLanguage";


export function MyNavbar() {

    const {theme, toggleTheme} = useTheme();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    function toggle() {
        toggleTheme();
    }

    const handleLanguageChange = (lang: string) => {
        changeLanguage(lang, i18n);
    };

    function getActiveKey(lo: Location): string {
        return '/' + lo.pathname.split('/')[1]
    }

    return (
        <Navbar expand="lg" className="bg-primary" data-bs-theme={theme}>
            <Container className="container-fluid">
                <NavbarBrand style={{}} href="/">eclara</NavbarBrand>
                <NavbarToggle aria-controls="basic-navbar-nav" />
                <NavbarCollapse id="basic-navbar-nav">
                    <Nav className="me-auto" variant="active" activeKey={getActiveKey(location)}>
                        <Nav.Link href="/tenants">Tenants</Nav.Link>
                        <Nav.Link href="/vehicles">Vehicles</Nav.Link>
                        <Nav.Link href="/users">Users</Nav.Link>
                        <Nav.Link href="/deactivated-users">DUsr</Nav.Link>
                        <Nav.Link href="/imports">Imports</Nav.Link>
                        <Nav.Link href="/orders">Orders</Nav.Link>
                        <Nav.Link href="/tax-test">Tax</Nav.Link>
                        <Nav.Link href="/countersign">Countersign</Nav.Link>
                        <Nav.Link href="/suppliers">Suppliers</Nav.Link>
                        <Nav.Link href="/other">Other</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto" variant="active" activeKey={getActiveKey(location)}>
                        <NavDropdown title={`Lang: ${i18n.language.toUpperCase()}`} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={() => handleLanguageChange('en')}>English</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleLanguageChange('de')}>Deutsch</NavDropdown.Item>
                            {/* Add other languages as necessary */}
                        </NavDropdown>
                        <Nav.Link className="justify-content-end" href="/login">{t('main.login')}</Nav.Link>
                        <Nav.Link className="justify-content-end" href="/logout">{t('main.logout')}</Nav.Link>
                    </Nav>
                </NavbarCollapse>
            </Container>
        </Navbar>

    )
}