import {Button, Form, FormControl, FormGroup, FormLabel, FormSelect} from "react-bootstrap";
import React, {useEffect} from "react";
import Container from "react-bootstrap/Container";
import {useNavigate} from "react-router-dom";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Loading} from "../../components/Loading";
import notifier from "simple-react-notifications2";
import {SupplierDto} from "../../model/supplier/supplier";
import {useSaveSupplier} from "../../api/suppliers/useSaveSuppliers";
import {useGetSupplierContracts} from "../../api/suppliers/useSupplierContracts";


export default function SupplierNew() {
    const navigate = useNavigate();
    const {loading: loadingSave, data: dataSave, error: errorSave, execute: executeSave} = useSaveSupplier();
    const {loading: loadingContracts, data: dataContracts, error: errorContracts, execute: executeContracts} = useGetSupplierContracts();

    useEffect(() => {
        executeContracts();
    }, []);

    const schema = Yup.object().shape({
        name: Yup.string().required('Required'),
        code: Yup.string().required('Required'),
        feature1Annex: Yup.string().nullable(),
        feature2Annex: Yup.string().nullable(),
        feature3Annex: Yup.string().nullable(),
    });

    function save(dto: SupplierDto) {
        executeSave(dto).then(res => {
            notifier.success("Saved")
            if(res?.id) {
                navigate(`/suppliers/${res?.id}`)
            } else {
                navigate(`/suppliers`)
            }
        });
    }

    function sortedContracts(): string[] {
        if(dataContracts) {
            return dataContracts.sort((a, b) => a.localeCompare(b))
        }
        return [];
    }

    const initialData = {id: 0, name: undefined, code: undefined} as SupplierDto

    return (
        <>
            <Container>

                <Formik initialValues={initialData} validationSchema={schema} onSubmit={values => save(values)}>
                    {({handleSubmit, setFieldValue, handleChange, handleBlur, values, touched, errors}) => (
                        <Form onSubmit={handleSubmit}>
                            {/*<Row>{JSON.stringify(values)}</Row>*/}
                            <FormGroup>
                                <FormLabel>Name</FormLabel>
                                <Field name="name" value={values.name} as={Form.Control}
                                       isInvalid={touched.name && !!errors.name} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.name}</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Code</FormLabel>
                                <Field name="code" value={values.code} as={Form.Control}
                                       isInvalid={touched.code && !!errors.code} onChange={handleChange}/>
                                <FormControl.Feedback type="invalid">{errors.code}</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup className="mb-3">
                                <FormLabel>feature1Annex</FormLabel>
                                <FormSelect
                                    name="feature1Annex" value={values.feature1Annex}
                                    isInvalid={touched.feature1Annex && !!errors.feature1Annex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.feature1Annex}</FormControl.Feedback>
                            </FormGroup>


                            <FormGroup className="mb-3">
                                <FormLabel>feature2Annex</FormLabel>
                                <FormSelect
                                    name="feature2Annex" value={values.feature2Annex}
                                    isInvalid={touched.feature2Annex && !!errors.feature2Annex} onChange={handleChange}
                                >
                                    <option value={undefined} label={''}/>
                                    {sortedContracts().map((m, i) => {
                                        return (<option key={i} value={m}>{m}</option>)
                                    })}
                                </FormSelect>
                                <FormControl.Feedback type="invalid">{errors.feature2Annex}</FormControl.Feedback>
                            </FormGroup>

                            <div className="mt-5 mb-5 d-grid gap-2 col-md-8 col-12 mx-auto">
                                {loadingSave && (<Loading/>)}
                                <Button disabled={loadingSave} variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Container>
        </>
    )
}