import React from 'react';
import styles from './Stepper2.module.scss';
import timelineStyle from './Timeline.module.scss';
import {OrderWorkflow, OrderWorkflowStep, OrderWorkflowUserStep} from "../../model/order/orderWorkflow";
import {DynamicStepComponentRenderer} from "./steps/DynamicStepComponentRenderer";
import {useTranslation} from "react-i18next";
import {DynamicTenantStepComponentRenderer} from "./steps/DynamicTenantStepComponentRenderer";


export function MyOrderStepper(
    props: {
        horizontal: boolean,
        orderId: number,
        steps: OrderWorkflowStep[],
        userSteps: OrderWorkflowUserStep[],
        reload: () => void,
        filterFirstUserSteps: boolean,
        isUser: boolean,
        isTenant: boolean,
        isAdmin: boolean,
    }
) {

    function filerStartUser(steps: OrderWorkflowStep[]): OrderWorkflowStep[] {
        let foundFalse = false;
        const filtered= steps.filter(item => {
            if (foundFalse) return false;
            if (!item.userEnabled) foundFalse = true;
            return item.userEnabled;
        });
        return filtered
    }

    // function userStepFinished(): OrderWorkflowStep {
    //     return {id: 0, stepType: 'USER_STEPS_FINISHED', status: {id: 0, stepId: 0, status: 'ACCEPTED', modified: new Date()},
    //         stepName: 'USER_STEPS_FINISHED', orderNumber: 5000, final: false, userEnabled: true, userVisible: true,
    //         adminVisible: false, adminEnabled: false, tenantAdminVisible: false, tenantAdminEnabled: false,}
    // }

    function currentStep(): OrderWorkflowStep {
        const steps = getSteps()
        for (const step of steps) {
            if(step.status !== undefined && (step.status.status === undefined || step.status.status === null) ) {
                return step
            }
        }
        // if(props.filterFirstUserSteps) steps.push(userStepFinished())
        return steps[steps.length - 1]
    }

    function findFinalStep(ow: OrderWorkflow): OrderWorkflowStep | undefined {
        return ow.steps.find(s => s.final && s.status !== undefined)
    }

    function filterNonBackgroundAndUpToFinalSteps() {
        const list: OrderWorkflowStep[] = []
        // todo what if goes to cancel - show completed step
        for (const step of props.steps.sort((a, b) => a.orderNumber - b.orderNumber)) {
            if(showStep(step)) {
                const completed = stepIsCompleted(step)
                const current = stepIsCurrent(step)
                if((current || completed) && step.final) {
                    list.push(step)
                    break;
                }
                if(!step.final) {
                    list.push(step)
                }
            }
        }
        return list
    }

    function showStep(step: OrderWorkflowStep): boolean {
        if(props.isAdmin && (step.adminVisible || step.backgroundExecutable)) return true
        if(props.isUser && step.userVisible) return true
        if(props.isTenant && step.tenantAdminVisible) return true
        return false
    }
    function getSteps() {
        if(props.filterFirstUserSteps) return filerStartUser(props.steps)
        return props.steps
    }


    if(props.horizontal) {
        return (
            <>
                <HorizontalStepperHeader steps={getSteps()}/>
                {/*<StepPreview step={currentStep()}/>*/}
                <DynamicStepComponentRenderer step={currentStep()} orderId={props.orderId} reload={props.reload}/>
            </>
        );
    }
    return (<VerticalTimelineStepper steps={filterNonBackgroundAndUpToFinalSteps()} orderId={props.orderId} reload={props.reload}/>);
}

function StepPreview(props: {step: OrderWorkflowStep}) {

    return (
        <>
            <div>Step Name: {props.step.stepName}</div>
            <div>Step status: {props.step.status?.status}</div>
        </>
    )
}

function VerticalTimelineStepper(
    props: {steps: OrderWorkflowStep[], orderId: number, reload: () => void}
) {

    function stepStatus(step: OrderWorkflowStep): string {
        if(step.final && step.success === false) {
            return 'c-timeline__content--finished-unsuccessful';
        }
        if(step.status === undefined) {
            return ''; // future
        }
        if(step.status.status !== undefined && !step.adminVisible) {
            return 'c-timeline__content--disabled';
        }
        if(step.status.status !== undefined) {
            return 'c-timeline__content--finished'; // current
        }

        return '';
    }

    function stepIsCurrent(step: OrderWorkflowStep): boolean {
        if(step.status) {
            if(!step.status.status) return true
        }
        return false
    }

    function getSteps(steps: OrderWorkflowStep[]) {
        const hasFinal = steps.find(s => s.final && s.status !== undefined) !== undefined
        return steps.filter(it => shouldFilterStep(it, hasFinal))
    }

    function shouldFilterStep(step: OrderWorkflowStep, hasFinal: boolean): boolean {
        if(hasFinal) {
            return (shouldShow(step) && step.status !== undefined) || (step.final && step.status !== undefined)
        }
        return shouldShow(step)
    }

    function shouldShow(item: OrderWorkflowStep) {
        return item.adminEnabled || item.adminVisible || item.backgroundExecutable
    }

    return (
        <div className={timelineStyle.wrapper}>
            <ol className={timelineStyle['c-timeline']}>
                {getSteps(props.steps).map((item, index) => (
                    // <li key={index} className={timelineStyle['c-timeline__item']}>
                    <li key={index} className={`${timelineStyle['c-timeline__item']} }`}>
                        <div className={`${timelineStyle['c-timeline__content']} ${timelineStyle[stepStatus(item)]}`} >
                            <h5 className={timelineStyle['c-timeline__title']}>{item.stepName}</h5>
                            <small className={timelineStyle['c-timeline__desc']}>{item.stepName}</small>
                            {(item.adminEnabled || item.adminVisible || item.backgroundExecutable) && (!item.status?.status ) && stepIsCurrent(item) && (
                                <div>
                                <DynamicTenantStepComponentRenderer step={item} orderId={props.orderId} reload={props.reload}/>
                                </div>
                            )}
                        </div>
                        {item.status?.modified && (
                            <div style={{width: "90px"}}>
                                <time>{item.status?.modified.toString().split('T')[0]}</time>
                                {stepIsCompleted(item) && (<div>Completed</div>)}
                                {!stepIsCompleted(item) && (<div>Action required</div>)}
                            </div>
                        )}
                        {!item.status?.modified && (
                            <div style={{width: "90px"}}></div>
                        )}
                    </li>
                ))}
            </ol>
        </div>
    );
}


export function HorizontalStepperHeader(
    props: {steps: OrderWorkflowStep[]}
) {
    const {t, i18n} = useTranslation();
    function getClass(step: OrderWorkflowStep): string {
        if(step.status === undefined) {
            return ''; // future
        }
        if(step.status.status !== undefined && step.status.status !== null) {
            return 'c-stepper__item--finished';
        }
        if(step.status.status === undefined || step.status.status === null) {
            return 'c-stepper__item--current'; // current
        }
        return '';
    }

    return (
        <div className="mt-3">
            <ol className={styles['c-stepper']}>
                {props.steps.map((step, index) => (
                    // <li key={index} className={styles['c-stepper__item']}>
                    <li key={index} className={`${styles['c-stepper__item']} ${styles[getClass(step)]}`}>
                        <h3 className={styles['c-stepper__title']}>{t(`steps.${step.stepType}`)}</h3>
                        {/*<p className={styles['c-stepper__desc']}>{step.stepName}</p>*/}
                    </li>
                ))}
            </ol>
        </div>
    );
}

function stepIsCurrent(step: OrderWorkflowStep): boolean {
    if(step.status) {
        if(!step.status.status) return true
    }
    return false
}

function stepIsCompleted(step: OrderWorkflowStep): boolean {
    if(step.status) {
        if(step.status.status) return true
    }
    return false
}
